@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap');


body {
  font-family: 'Poppins', sans-serif;
}

input {
  outline: none;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bottom-border-input {
  border-bottom: 1px solid #0e0e0e;
  max-width: 60px;
}

.d-none {
  display: none;
}

.w-30 {
  width: 30px;
}

.w-45 {
  width: 45px;
}

.inline-block {
  display: inline-block;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.mb-2 {
  margin-bottom: 10px;
}

.text-right {
  text-align: end;
}

.card-text {
  color: #6E6B7B;
  font-size: .9rem;
}

.color-medium {
  color: #6E6B7B;
}

.btn-outline-danger {
  border: 1px solid #EA5455 !important;
  background-color: transparent;
  color: #EA5455;
  border-radius: 5px !important;
}

.charges-table {
  background: #f8f8f8;
  padding: 7px 0px;
  border-radius: 2px;
}

.th-class {
  text-align: center;
  border-bottom: 0px;
  border-top: 0px;
}

.success-color {
  color: #34a853;
}

.danger-color {
  color: #dc3545;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.col-xl-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-md-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-md-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-xl-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-md-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-lg-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xl-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-lg-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-md-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-md-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-lg-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.714rem;
  padding: 0.438rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6E6B7B;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #D8D6DE;
  border-radius: 0.357rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

label {
  color: #5E5873;
  font-size: 0.857rem;
  display: inline-block;
  margin-bottom: 0.2857rem;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 0;
  border-bottom-left-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
  color: #6E6B7B;
  border-collapse: collapse;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}

.table td {
  padding: 5px 5px;
  vertical-align: middle;
  font-size: 12px;
}

.table thead th {
  text-transform: capitalize;
  font-size: 0.8rem;
  vertical-align: top;
  letter-spacing: 0.5px;
  padding: 5px 5px;
  background-color: #F3F2F7;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #ededed;
}

.text-center {
  text-align: center;
}

.css-13cymwt-control {
  min-height: 45px !important;
}

.bandcTable {
  min-height: 200px;
  max-height: 500px;
  height: 500px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.cborder {
  border-right: 2px dashed #eaeaea;
  border-bottom: 2px dashed #eaeaea;
  padding-top: 7px;
}

.c-b {
  color: #547bf8;
}

.c-v {
  color: #3c63b9;
}

.c-g {
  color: #429d19;
}

.c-vo {
  color: #089475;
}

.c-p {
  color: #bc2e94;
}

.c-v {
  color: #654ecc;
}

.c-r {
  color: #ff4395;
}

.c-vo {
  color: #33c0ec;
}

.c-vi {
  color: #006ca8;
}

.c-lp {
  color: #af7ece;
}

.font-12 {
  font-size: 12px;
}

.nav-tabs {
  margin-bottom: 1rem;
  position: relative;
}

.nav {
  border-radius: 0.25rem;
}

.nav-tabs {
  border-bottom: 0 solid #DAE1E7;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-tabs .nav-item {
  position: relative;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link.active {
  position: relative;
  color: #7367F0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #7367F0;
  background-color: transparent;
  border-color: #DAE1E7 #DAE1E7 transparent;
}

.nav-tabs .nav-link {
  color: #6E6B7B;
  border: none;
  min-width: auto;
  font-weight: 450;
  padding: 0.61rem 1.2rem;
  border-radius: 0;
  position: relative;
  overflow: hidden;
}

.nav-pills .nav-link,
.nav-tabs .nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-tabs .nav-link {
  border: 0 solid transparent;
  border-top-left-radius: 0.25;
  border-top-right-radius: 0.25;
}

.nav-link {
  display: block;
  padding: 0.358rem 0.5rem;
}

.nav-tabs .nav-link.active:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.nav-tabs .nav-link:after {
  background: linear-gradient(30deg, #34a853, #34a85361) !important;
}

.nav-tabs .nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: -webkit-linear-gradient(60deg, #7367F0, rgba(115, 103, 240, 0.5)) !important;
  background: linear-gradient(30deg, #7367F0, rgba(115, 103, 240, 0.5)) !important;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, 150%, 0);
  transform: translate3d(0, 150%, 0);
}

.nav-tabs .nav-item {
  position: relative;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.tab-list {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab-list button {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
}

.tab-list button:hover {
  border-bottom: 2px solid #34a853;
}

.tab-list button.active {
  border-bottom: 2px solid #34a853;
  font-weight: 500;
  color: #34a853;
}

.tab-content {
  padding: 10px;
  margin-top: 20px
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  position: relative;
  background: white;
  border-radius: 8px;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.modal .modal-content {
  border: none;
  overflow: visible;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(34, 41, 47, 0.2);
  border-radius: 0.358rem;
  outline: 0;
}

.modal .modal-header {
  background-color: #F8F8F8;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.8rem 1.4rem;
  border-bottom: 1px solid #EBE9F1;
  border-top-left-radius: calc(0.358rem - 1px);
  border-top-right-radius: calc(0.358rem - 1px);
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.45;
}

.modal .modal-header .close {
  padding: 0.2rem 0.62rem;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.357rem;
  background: #FFFFFF;
  opacity: 1;
  -webkit-transition: all 0.23s ease 0.1s;
  transition: all 0.23s ease 0.1s;
  position: relative;
  -webkit-transform: translate(8px, -2px);
  -ms-transform: translate(8px, -2px);
  transform: translate(8px, -2px);
}

.modal-header .close {
  padding: 0.8rem 1.4rem;
  margin: -0.8rem -1.4rem -0.8rem auto;
}

.close {
  float: right;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  color: #5E5873;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: 0.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.8rem 1.4rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}


/* Dropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #6c757d;
  /* Secondary button color */
  color: white;
  border: 1px solid #6c757d;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.dropdown-toggle:hover {
  background-color: #5a6268;
  /* Darker shade on hover */
  border-color: #545b62;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-item:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.dropdown-toggle::after {
  content: "▼";
  font-size: 10px;
  margin-left: 5px;
}







/* justify-content: space-evenly;
justify-items: center;
align-items: stretch; */